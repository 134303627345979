<app-header></app-header>

<div class="section" style="margin-top: 3%;" id="join">
  <div class="container" style="margin-top: 50px; margin-bottom:50px;">
    <div class="row display-flex-center ">
      <div class="col-md-12 col-xs-12" >
        <h1 class="text-center">Join Us</h1>
        <br />
      </div>
    </div>
    <div class="row display-flex-center" style="margin: 10px 0 50px 0;">
      <div class="col-md-6 col-xs-12">
        <img src="./assets/image/driver_agent.jpg" data-aos="zoom-in-down" class="center-block img-responsive">
      </div>
      <div class="col-md-6 col-xs-12">
        <h4 class="text-left">Line Clear Express Authorise Delivery Agent</h4>
        <p class="text-justify">The most important task in courier is delivering shipments to their respective
          receivers.
          Whether you are an individual or a company, we welcome you to become our Line Clear Authorise Service Partner.
          &nbsp;</p>
        <p class="text-justify"><b>Your Task</b></p>
        <table class="text-justify">
          <tr>
            <td><i class="fa fa-check pl-3" aria-hidden="true"></i></td>
            <td>Deliver without delay all assigned shipments for the day</td>
          </tr>
          <tr>
            <td><i class="fa fa-check pl-3" aria-hidden="true"></i></td>
            <td>Deliver with us 7 days a week</td>
          </tr>
          <tr>
            <td><i class="fa fa-check pl-3" aria-hidden="true"></i></td>
            <td>Electronically record all shipment process activities via our app</td>
          </tr>
          <tr>
            <td><i class="fa fa-check pl-3" aria-hidden="true"></i></td>
            <td>Perform all duties professionally and courteously with respect to all road users.</td>
          </tr>
          <tr>
            <td><i class="fa fa-check pl-3" aria-hidden="true"></i></td>
            <td>Ensure the security and integrity of all shipments under your care.</td>
          </tr>
        </table>

        <p class="text-justify mt-2"><b>Line Clear Offers You</b></p>
        <table class="text-justify">
          <tr>
            <td><i class="fa fa-check pl-3" aria-hidden="true"></i></td>
            <td>2x Pay Out Every Month</td>
          </tr>
          <tr>
            <td><i class="fa fa-check pl-3" aria-hidden="true"></i></td>
            <td>Delivery of all shipments</td>
          </tr>
          <tr>
            <td><i class="fa fa-check pl-3" aria-hidden="true"></i></td>
            <td>Vehicle Leasing</td>
          </tr>
          <tr>
            <td><i class="fa fa-check pl-3" aria-hidden="true"></i></td>
            <td>Incentive</td>
          </tr>
        </table>

        <p class="text-justify mt-2"><b>Requirements</b></p>
        <table class="text-justify">
          <tr>
            <td><i class="fa fa-check pl-3" aria-hidden="true"></i></td>
            <td>Must be Malaysian or Malaysian PR</td>
          </tr>
          <tr>
            <td><i class="fa fa-check pl-3" aria-hidden="true"></i></td>
            <td>Valid driver’s license or GDL License</td>
          </tr>
          <tr>
            <td><i class="fa fa-check pl-3" aria-hidden="true"></i></td>
            <td>Good driving record</td>
          </tr>
          <tr>
            <td><i class="fa fa-check pl-3" aria-hidden="true"></i></td>
            <td>Good spoken and written in English and Bahasa Malaysia</td>
          </tr>
        </table>
      
        <form (submit)="DeiverAgent()">
          <div class="input-group-lg mobile-center" style="text-align: right; padding-top: 10px;">
            <span class="input-group-btn">
              <button class="btn track-btn" type="submit" id="button" value="Click">Join Now<i
                  class="fa fa-flip-horizontal fa-truck"></i></button>
            </span>
          </div>
        </form>
      </div>
    </div>

    <div style="border-top: 1px dashed Silver;"></div>

    <div class="row display-flex-center" style="margin: 50px 0 10px 0;">
      <div class="col-md-6 col-xs-12">
        <img src="./assets/image/JoinUsStation.jpg" data-aos="zoom-in-down" class="center-block img-responsive">
      </div>
      <div class="col-md-6 col-xs-12">
        <h4 class="text-left">Line Clear Express Station</h4>
        <p class="text-justify">Line Clear Express Stations are official affiliated courier partners of
          Line Clear Express that allows customers to easily drop off their parcels
          for delivery across Malaysia. &nbsp;</p>
        <form (submit)="JoinNow()">
          <div class="input-group-lg mobile-center" style="text-align: right; padding-top: 10px;">
            <span class="input-group-btn">
              <button class="btn track-btn" type="submit" id="button" value="Click">Join Now<i
                  class="fa fa-flip-horizontal fa-users"></i></button>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>




<!-- <div class="section" style="margin-top: 5%;" id="join">
  <div class="container" style="margin-top: 50px; margin-bottom:50px;">
    <div class="row display-flex-center ">
      <div class="col-md-6 col-xs-12">
        <img src="./assets/image/JoinUsStation.jpg" data-aos="zoom-in-down" class="center-block img-responsive">
      </div>
      <div class="col-md-6 col-xs-12">
        <h1 class="text-left">Join Us</h1>
        <br />
        <h4 class="text-left">Line Clear Express Station</h4>
        <p class="text-justify">Line Clear Express Stations are official affiliated courier partners of
          Line Clear Express that allows customers to easily drop off their parcels
          for delivery across Malaysia. &nbsp;</p>
        <form (submit)="JoinNow()">
          <div class="input-group-lg mobile-center" style="text-align: right; padding-top: 10px;">
            <span class="input-group-btn">
              <button class="btn track-btn" type="submit" id="button" value="Click">Join Now<i
                  class="fa fa-flip-horizontal fa-users"></i></button>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div> -->



  <app-footer></app-footer>