import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  email: any;
  yearCurrent: any;

  constructor(
    private apiService: ApiService,
    private route: Router,
    private viewportScroller: ViewportScroller
  ) { }

  ngOnInit(): void {
  }

  

  goToTnc(){
    //this.route.navigate(['tnc']);
    window.open('https://lineclearexpress.com/doc/Terms_and_Conditions.pdf', '_blank');
  }

  goToPrepaid(){
    //this.route.navigate(['tnc']);
    //https://lineclearexpress.com/doc/About_Line_Clear_Express_Prepaid.pdf
    window.open('https://lineclearexpress.com/doc/About_Line_Clear_Express_Prepaid.pdf', '_blank');
  }

  // goToCarriage(){
  //   //this.route.navigate(['tnc-carriage']);
  //   window.open('https://lineclearexpress.com/doc/Terms_and_Conditions_Carriage.pdf', '_blank');
  // }

  goToPolicy(){
     this.route.navigate(['policy']);

  }

  goToPrivacyPolicy(){
   // this.route.navigate(['privacy_policy']);
    window.open('https://lineclearexpress.com/doc/privacy_policy.pdf', '_blank');
  }

  goToLanding(){

    this.route.navigate(['']);
  }
  
  goToService(){
    window.location.href = '/#AnchorId';
    // this.route.navigateByUrl('/landing#AnchorID');
    // this.router.navigate(['landing'], { fragment: 'AnchorID' });
  }

  goToQuote(){
    this.route.navigate(['quotation']);
  }

  goToPitstop(){
    this.route.navigate(['pitstop']);
  }

  goToAbout(){
    this.route.navigate(['about-us']);
  }

  goToTracker(){
    this.route.navigate(['tracker']);
  }

  goToContact(){
    this.route.navigate(['contactus']);
  }

  goToShipment(){
    this.route.navigate(['shipment']);
  }

  goToPortal(){
    window.open('https://www.lineclearexpressonline.com/#/auth/login');
  }

  openPromo(){
    window.open('https://lineclearexpress.com/doc/promo.pdf', '_blank');
  }

  openRate(){
    window.open('https://lineclearexpress.com/doc/price_rate.pdf', '_blank');
  }

  openLocalRate(){
    window.open('https://lineclearexpress.com/doc/walk_in_rate.pdf', '_blank');
  }

  openPrepaidCoin(){
    this.route.navigate(['prepaid-intro']);
  }

  // openLineshield(){
  //   // window.open('https://lineclearexpress.com/doc/lineshield.pdf', '_blank');
  //   this.route.navigate(['lineshield']);
  // }
  openProtectionPlan(){
    this.route.navigate(['protection-plan']);
  }
  goToLineClearShop(){
    window.location.href = 'https://lineclear.shop/';
    // this.route.navigateByUrl('/landing#AnchorID');
    // this.router.navigate(['landing'], { fragment: 'AnchorID' });
  }

  goToFB(){
    window.open('https://www.facebook.com/LineClearExpressMY/?ref=page_internal', '_blank');
  }

  goToInsta(){
    window.open('https://www.instagram.com/lineclearexpress/', '_blank');
  }

  goToYoutube(){
      window.open('https://www.youtube.com/channel/UCVkMuHnwc-XKdidhuyRo5Qw', '_blank');
  }

  goToTwitter(){
      window.open('https://twitter.com/lineclearxpress', '_blank');
  }

  // colorWinTnc(){
  //   window.open('https://lineclearexpress.com/doc/colorwin_tnc.pdf', '_blank');
  // }

  // hantarMenangTnc(){
  //   window.open('https://lineclearexpress.com/doc/HantarDanMenang.pdf', '_blank');
  // }

  goToFaq(){
    this.route.navigate(['faq']);
  }

  public onClick(elementId: string): void { 

    this.viewportScroller.scrollToAnchor(elementId);
    
    //   window.location.href = '/v2/landing#AnchorId';
  }
  
  public goToFragment(fragment: string): void{

    // let elementId = 'serviceId'
    
    // this.route.navigate( ['/landing#'], {fragment: fragment});
    // this.route.navigate(['/landing#'], { fragment: 'serviceId' });
    // this.route.navigate(['/landing#'+fragment])
    // this.redirectTo('/landing#serviceId');
    // window.location.href = '/v2/landing#' + fragment;
    // window.location.href = '/landing#' + fragment;
    // window.location.href = 'https://lineclearexpress.com/v2/landing#serviceId'
    // window.location.replace("http://someUrl.com");
    // this.viewportScroller.scrollToAnchor(elementId);

    // production
    // this.route.navigateByUrl("/v2/landing#serviceId")

    // staging 
    this.route.navigateByUrl("/#serviceId")
    
  }

  
}

