<!-- Desktop -->
<div class="section" style="background-color: black">
  <div class="container">
    <div class="row">
      <div class="col-sm-3" style="margin-bottom: 20px">
        <h4 class="heading-color">Quick Link</h4>
        <a href="javascript:;" (click)="goToTracker()"
          ><p>Track &amp; Trace</p></a
        >
        <a href="javascript:;" (click)="goToQuote()"><p>Instant Quote</p></a>
        <a href="javascript:;" (click)="goToPortal()"><p>Customer Portal</p></a>
        <a href="javascript:;" (click)="openRate()"
          ><p>International Price Rate</p></a
        >
        <a href="javascript:;" (click)="openLocalRate()"
          ><p>Local Price Rate</p></a
        >

        <!-- <a href="javascript:;" (click)="openPromo()"><p>Promo Terms &amp; Condition</p></a> -->
        <!-- <a href="javascript:;" (click)="colorWinTnc()"><p>Colour &amp; WIn Contest</p></a>
              <a href="javascript:;" (click)="hantarMenangTnc()"><p>Hantar Dan Menang</p></a> -->
      </div>
      <div class="col-sm-2" style="margin-bottom: 20px">
        <h4 class="heading-color">Services</h4>
        <a href="javascript:;" (click)="goToFragment('serviceId')"
          ><p>Courier</p></a
        >
        <a href="javascript:;" (click)="goToFragment('serviceId')"
          ><p>Warehouse Logistics</p></a
        >
        <a href="javascript:;" (click)="goToFragment('serviceId')"
          ><p>Freight Forwarding</p></a
        >
        <a href="javascript:;" (click)="openProtectionPlan()"><p>Protection Plan</p></a>
        <a href="javascript:;" (click)="goToLineClearShop()"><p>Line Clear Shop</p></a
        >
        <a href="javascript:;" (click)="openPrepaidCoin()"><p>Prepaid</p></a>
      </div>
      <div class="col-sm-2" style="margin-bottom: 20px">
        <h4 class="heading-color">Company</h4>
        <a href="javascript:;" (click)="goToAbout()"><p>About Us</p></a>
        <a href="javascript:;" (click)="goToContact()"><p>Contact Us</p></a>
      </div>
      <div class="col-sm-3" style="margin-bottom: 20px">
        <h4 class="heading-color">Policy</h4>
        <a href="javascript:;" (click)="goToShipment()"
          ><p>Export Regulation</p></a
        >
        <a href="javascript:;" (click)="goToTnc()"
          ><p>Terms and Conditions</p></a
        >
        <!-- <a href="javascript:;" (click)="goToCarriage()"><p>Terms and Conditions of Carriage</p></a> -->
        <a href="javascript:;" (click)="goToPolicy()"><p>Company Policy</p></a>
        <a href="javascript:;" (click)="goToPrivacyPolicy()"
          ><p>Privacy Policy</p></a
        >
        <a href="javascript:;" (click)="goToFaq()"><p>FAQ</p></a>
      </div>
      <div class="col-sm-2" style="margin-bottom: 20px; margin-right: 0">
        <h4 class="heading-color">Follow Us</h4>
        <div class="row mobile-w" style="margin-top: 20px; margin-bottom: 10px">
          <div class="col-xs-3 text-center">
            <a (click)="goToFB()" class="point"
              ><i
                class="fa fa-2x fa-fw fa-facebook"
                style="color: white; font-size: 22px"
              ></i
            ></a>
          </div>
          <div class="col-xs-3">
            <a (click)="goToTwitter()" class="point"
              ><i
                class="fa fa-2x fa-fw fa-twitter"
                style="color: white; font-size: 22px"
              ></i
            ></a>
          </div>
          <div class="col-xs-3">
            <a (click)="goToInsta()" class="point"
              ><i
                class="fa fa-2x fa-fw fa-instagram"
                style="color: white; font-size: 22px"
              ></i
            ></a>
          </div>
          <div class="col-xs-3 text-center">
            <a (click)="goToYoutube()" class="point"
              ><i
                class="fa fa-2x fa-fw hub fa-youtube"
                style="color: white; font-size: 22px"
              ></i
            ></a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p style="color: white; margin-top: 30px" class="text-center">
          © Copyright 2024 Line Clear Express Sdn Bhd (201301002482 (1032320-W)). All Rights Reserved.
        </p>
      </div>
    </div>
  </div>
</div>
