<app-header></app-header>


<!--Section 0 - Image-->
<div class="" id="">
  <div class="">
    <img class="prepaid-image" src="./assets/image/05-PREPAID-DESKTOP.png" >
  </div>

</div>


<!--Section 1-->
<div class="section">
  <div class="container">
    <div class="col-md-12">
      <div class="">
        <h1 class="prepaid-title"> Pay with Line Clear Coins <br> <br></h1>

        <p class="prepaid-text">


          We are excited to introduce Line Clear Express Prepaid Solution! You can now opt
          to make payment for your shipments with Line Clear Coins and earn reward coins
          for your next purchase.
          <br> <br>
          Line Clear Coins is the official virtual currency of Line Clear Express. Use it to pay
          for your shipments and earn reward coins for your next purchase. The more you
          use the more reward coins you get.
        </p>
        <br>

        <form (submit)="prepaid()">
          <div class="input-group-lg mobile-center" style="padding-top: 30px;">
            <span class="input-group-btn">
              <button class="btn btn-primary prepaid-btn" type="submit" id="button" value="Click">Learn more</button>
            </span>
          </div>
        </form>
      </div>



    </div>
  </div>
</div>




<app-footer></app-footer>