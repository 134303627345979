<div class="section" style="margin-top: 50px;">
  <div class="container-fluid">
    <div class="row" style="margin-bottom:30px;">
      <div class="col-md-12">
        <h1 class="text-center" style=" font-family: 'Conthrax', Arial, Helvetica, sans-serif !important;">
          <i>Scan2Deliver</i>
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="col-md-2 col-md-offset-1 col-sm-12 col-sm-offset-1" style="padding-bottom: 30px;">
          <img src="./assets/image/sectionmain-01.png" data-aos="zoom-in-down"
            class="center-block img-responsive img-size">
          <h4 class="text-center">STEP 1
            <br>
          </h4>
          <p class="text-center">Customer places order on web or app and QR code is generated</p>
        </div>
        <div class="col-md-2 col-sm-12" style="padding-bottom: 30px;">
          <img src="./assets/image/sectionmain-02.png" data-aos="zoom-in-down"
            class="center-block img-responsive img-size">
          <h4 class="text-center">STEP 2</h4>
          <p class="text-center">Courier scans QR code when parcel is picked up at customer location</p>
        </div>
        <div class="col-md-2 col-sm-12" style="padding-bottom: 30px; ">
          <img src="./assets/image/sectionmain-03.png" data-aos="zoom-in-down"
            class="center-block img-responsive img-size">
          <h4 class="text-center">STEP 3</h4>
          <p class="text-center">QR code is used to identify and sort parcel at hub or branch</p>
        </div>
        <div class="col-md-2 col-sm-12" style="padding-bottom: 30px; ">
          <img src="./assets/image/sectionmain-04.png" data-aos="zoom-in-down"
            class="center-block img-responsive img-size">
          <h4 class="text-center">STEP 4
            <br>
          </h4>
          <p class="text-center">Courier scans QR code to make delivery</p>
        </div>
        <div class="col-md-2 col-sm-12" style="padding-bottom: 30px;">
          <img src="./assets/image/sectionmain-05.png" data-aos="zoom-in-down"
            class="center-block img-responsive img-size">
          <h4 class="text-center">STEP 5</h4>
          <p class="text-center">Recipient presents QR code as identification to take delivery from courier</p>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="section" style="margin-top: 50px; padding-bottom: 60px; background-color:whitesmoke">
  <div class="container-fluid">
    <div class="row" style="margin-bottom:20px;">
      <div class="col-md-12">
        <h1 class="text-center">Follow Us</h1>
      </div>
    </div>
    <ngx-slick-carousel class="slick-c" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)"
      (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
      <div ngxSlickItem *ngFor="let slide of slides" class="slide">
        <a (click)="goToFB()" class="point"><img src="{{ slide.img }}" alt="" width="100%"></a>
      </div>
    </ngx-slick-carousel>

  </div>
</div>

<div class="section" style="padding-top:100px; padding-bottom:100px;">
  <div class="container">
    <div class="row display-flex-center">
      <div class="col-md-5  mobile-center">
        <h1>Integrated Supply Chain Management</h1>
        <p>Customers expect next-day or same-day shipping as standard. Using a third-party
          logistics provider allows you to offer fast shipping no matter where the
          order is being sent, thanks to having access to the 3PL’s wide distribution
          network</p>
        <a [routerLink]="['/contactus']" class="btn btn-primary track-btn" style="margin-bottom: 30px;">Find Out
          More</a>
      </div>
      <div class="col-md-7">
        <img
          src="./assets/image/[image.shutterstock.com][45]shopping-online-process-delivery-digital-260nw-1900460815.jpg"
          data-aos="zoom-in-down" class="center-block img-responsive mobile-center">
      </div>
    </div>
  </div>
</div>

<div class="section" style="padding-top:40px; padding-bottom: 40px; background-color: #3FC0F0;">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <iframe width="100%" height="400" src="https://www.youtube.com/embed/1uLksjJhU8E" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
        </iframe>

      </div>

    </div>
  </div>
  <div id="serviceId"></div>
</div>
<div class="section" style="padding:0;">
  <div class="container-fluid"></div>
</div>
<div class="section">
  <div class="container">
    <div class="row" style="margin-bottom: 50px;">
      <div class="col-md-12">
        <h1 class="text-center">
          <b>Our Services</b>
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <ul class="media-list">
          <li data-aos="zoom-in-down" class="media">
            <a class="pull-left"><img class="center-block media-object" src="./assets/image/express-delivery.png"
                height="100" style="padding-right: 20px;"></a>
            <div class="media-body" style="height :150px;">
              <h4 class="media-heading">
                <b>Courier Services</b>
              </h4>
              <p class="text-justify">Line Clear offers economic domestics and overseas delivery services to
                over 200 countries through its trusted international network alliances
                and supported by online tracking customers.</p>
            </div>
          </li>
          <li data-aos="zoom-in-down" class="media" style="height :150px;">
            <a class="pull-left"  (click)="openProtectionPlan()" style="cursor:pointer" target="_blank"><img class="center-block media-object" src="./assets/image/Logo-Protection-Plan.png"
                height="100" style="padding-right: 20px;"></a>
            <div class="media-body">
              <h4 class="media-heading">
                <b>Protection Plan</b>
              </h4>
              <p class="text-justify">Protection Plan offers essential to cover and peace of mind to the customer
                by protecting their valuable shipment from all risks during the course
                of carriage up to MY50,000.00.</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <ul class="media-list">
          <li data-aos="zoom-in-down" class="media" style="height :150px;">
            <a class="pull-left"><img class="center-block media-object" src="./assets/image/warehouse.png" height="100"
                style="padding-right: 20px;"></a>
            <div class="media-body">
              <h4 class="media-heading">
                <b>Warehousing Logistics</b>
              </h4>
              <p class="text-justify">Offers temporary and long-term warehouse storage in a systematic and orderly
                manner with the secure storage facilities to give our customer flexibility
                to arrange their shipment for companies that seeking cost-effective solutions.</p>
            </div>
          </li>
          <li data-aos="zoom-in-down" class="media" style="height :150px;">
            <a class="pull-left"><img class="center-block media-object" src="./assets/image/worldwide-shipping.png"
                height="100" style="padding-right: 20px;"></a>
            <div class="media-body">
              <h4 class="media-heading">
                <b>Freight Forwarding</b>
              </h4>
              <p class="text-justify">We have Overseas Agent Worldwide for all your shipment needs. Line Clear
                provides efficient coordination for every shipment of goods.</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="container">
      <div class="row" style="margin-bottom: 50px;">
        <div class="col-md-12">
          <h1 class="text-center">
            <b>Our Certification</b>
          </h1>
          <p>Line Clear Express has been awarded with the prestigious ISO 14001:2015 and ISO 9001:2015 certifications.
            ISO 14001:2015 is a globally recognized standard that outlines the requirements for an effective
            environmental management system (EMS),
            while ISO 9001:2015 is the standard for a quality management system (QMS). Line Clear Express's achievement
            of both these
            certifications demonstrates the company’s commitment to reducing environmental impact, implementing
            sustainable practices,
            and maintaining high-quality services for their customers. These awards are a testament to our dedication
            and hard work in
            ensuring that our business is conducted in an environmentally responsible and quality-oriented manner.</p>
          <br />

          <p>Line Clear Express' business adheres to the following legal requirements:</p>
          <ul>
            <li>MCMC Act</li>
            <li>APAD Act 2010</li>
            <li>LPKP Act 1987</li>
            <li>EQA 1974</li>
            <li>OSHA 1994</li>
            <li>FMA 1967</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <ul class="media-list">
            <li data-aos="zoom-in-down" class="">
              <div><a href="https://www.sgs.com/en-my" target="_blank" style="border-color: black;"><img
                    src="./assets/image/14001@2x-100.png" data-aos="zoom-in-down" class="img-responsive"
                    style="width:80%; height:60%; margin-left: 10%; border-style: solid; border-width: 2px;border-color: black;"></a>
                <div class="row"></div>
                <h5 style="text-align: center; margin-bottom: 7%;">ISO 9001:2015 Quality Management System</h5>
              </div>
            </li>
            <li data-aos="zoom-in-down" class="">
              <div><a href="https://www.sgs.com/en-my" target="_blank" style="border-color: black;">
                  <img src="./assets/image/9001@2x-100.png" data-aos="zoom-in-down" class=" img-responsive"
                    style="width:80%; height:60%; margin-left: 10%;border-style: solid; border-width: 2px; border-color: black;"></a>
                <div class="row"></div>
                <h5 style="text-align: center; margin-bottom: 7%;">ISO 9001:2015 Quality Management System</h5>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <ul class="media-list">
            <li data-aos="zoom-in-down" class="">
              <div><a href="https://www.sgs.com/en-my" target="_blank" style="border-color: black;">
                  <img src="./assets/image/14001 MS@2x-100.png" data-aos="zoom-in-down" class="img-responsive"
                    style="width:80%; height:60%; margin-left: 10%;border-style: solid; border-width: 2px; border-color: black;"></a>
                <div class="row"></div>
                <h5 style="text-align: center; margin-bottom: 7%;">ISO 14001:2015 Environmental Management Systems</h5>
              </div>
            </li>
            <li data-aos="zoom-in-down" class="">
              <div><a href="https://www.sgs.com/en-my" target="_blank" style="border-color: black;">
                  <img src="./assets/image/9001 MS@2x-100.png" data-aos="zoom-in-down" class="img-responsive"
                    style="width:80%; height:60%; margin-left: 10%;border-style: solid; border-width: 2px; border-color: black;"></a>
                <div class="row"></div>
                <h5 style="text-align: center; margin-bottom: 7%;">ISO 9001:2015 Quality Management System</h5>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</div>