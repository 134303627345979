import { Component, ViewChild } from '@angular/core';
// import { LiveChatWidgetModel, LiveChatWidgetApiModel, LivechatWidgetComponent} from '@livechat/angular-widget';
import { Title, Meta } from '@angular/platform-browser'; 

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
//   public isLiveChatWidgetLoaded: boolean = false;
//   public liveChatApi: LiveChatWidgetApiModel;

  title = 'lineclear';

  // @ViewChild('liveChatWidget', {static: false}) public liveChatWidget: LiveChatWidgetModel;

  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle("Scan 2 Delivery Express Malaysia | Line Clear Express");
    
    const metaDesc = this.metaService.getTag('name=description'); 

    if(!metaDesc){

        this.metaService.addTags([
            {name: "description", content: "Provides nationwide courier and  integrated supply chain management solutions from warehousing, pick and pack, last mile delivery and track and trace. Customers are empowered through the Line Clear's smart Scan2Deliver technology to make end-to-end delivery using the web or app."}
        ]);
    }else{

        this.metaService.updateTag(  
            { name: "description", content: "Provides nationwide courier and  integrated supply chain management solutions from warehousing, pick and pack, last mile delivery and track and trace. Customers are empowered through the Line Clear's smart Scan2Deliver technology to make end-to-end delivery using the web or app." },  
            "name=description"  
          )  
    }
  
    
  }

  
  // onChatLoaded(api: LiveChatWidgetApiModel): void {
  //   this.liveChatApi = api;
  //   this.isLiveChatWidgetLoaded = true;
  
  // }
  


}
