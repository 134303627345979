import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-prepaid',
  templateUrl: './prepaid.component.html',
  styleUrls: ['./prepaid.component.css']
})
export class PrepaidComponent implements OnInit {
  constructor(
    private route: Router,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Prepaid | Line Clear Express")
  }


  prepaid() {
    //window.open('https://lineclearexpress.com/doc/About_Line_Clear_Express_Prepaid.pdf', '_blank');
    this.route.navigate(['prepaid-intro']);
  }

}

