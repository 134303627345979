import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser'; 

@Component({
  selector: 'app-join-us',
  templateUrl: './join-us.component.html',
  styleUrls: ['./join-us.component.css']
})
export class JoinUsComponent implements OnInit {
  
  constructor(
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit(): void {
        this.titleService.setTitle("Line Clear Express Station | Express Delivery Service | Line Clear Express")

        const metaDesc = this.metaService.getTag('name=description'); 

        if(!metaDesc){
            this.metaService.addTags([
                {name: "description", content: "Line Clear Express Stations are official affiliated courier partners of Line Clear Express that allows customers to easily drop off their parcels for delivery across Malaysia."}
            ]);
        }else{
            this.metaService.updateTag(  
                {name: "description", content: "Line Clear Express Stations are official affiliated courier partners of Line Clear Express that allows customers to easily drop off their parcels for delivery across Malaysia." },  
                "name=description"  
            )  
        }
  }

  JoinNow(){
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSeZWC51uutG4WV4Zr-6zgk7fm5Vs885grxDGnTHmOooKLT-xg/viewform', '_blank');
  
  }
  
  DeiverAgent(){
    window.open('https://docs.google.com/forms/d/e/1FAIpQLScbF7e58TzQQO4hYXKdp45Hi7vTys_aocCGs6fI0gMT8b2eQw/viewform', '_blank');
  }

}
