<app-header></app-header>
<div
  class="section display-flex-center"
  style="
    margin-top: 170px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  "
>
  <h1>Line Clear International Shipment - Export Regulation</h1>
</div>

<!--Checkbox-->
<div id="list1" class="dropdown-check-list dropdown" tabindex="100">
  <span class="anchor" (click)="onclick()">Select Country</span>
  <div class="items dropdown-menu checkbox-menu allow-focus">
    <!-- * Retrieve from Country table-->
    <ng-container *ngFor="let country of exports?.countries">
      <input
        type="checkbox"
        [checked]="country.id === 1"
        [name]="country.name"
        [id]="country.name"
        (change)="toggleShow(country.id)"
      />
      <label [for]="country.name">&nbsp;{{ country.name }}</label
      ><br />
    </ng-container>
  </div>
</div>

<!--Export Regulations Table-->
<div
  class="section center"
  style="overflow-x: auto; margin-top: 20px; margin-bottom: 100px"
>
  <table class="shipmentable" style="width: 50%">
    <thead>
      <!--Head-->
      <tr>
        <th class="cl0">Category</th>
        <th class="cl1">Description</th>
        <!--Country Name-->
        <ng-container *ngFor="let country of exports?.countries">
          <th class="cl4" *ngIf="isShown[country.id]">
            {{ country.name }}
          </th>
        </ng-container>
      </tr>
    </thead>

    <!--Body-->
    <tbody>
      <ng-container *ngFor="let shipment of exports?.shipments">
        <tr>
          <td class="cl0">{{ shipment.Category }}</td>
          <td class="cl1">{{ shipment.Description }}</td>
          <!-- Regulation Status -->
          <ng-container *ngFor="let country of exports?.countries">
            <td
              *ngIf="isShown[country.id]"
              [ngStyle]="
                getCellStyle(
                  getRegulationsByCountryAndShipment(country.id, shipment.id)[0]
                )
              "
            >
              {{
                getRegulationsByCountryAndShipment(country.id, shipment.id)[0]
                  ?.status
              }}
            </td>
          </ng-container>
        </tr>
      </ng-container>
    </tbody>

    <!---->
  </table>
</div>

<!---->
<div
  class="section"
  style="overflow-x: auto; margin-top: 35px; margin-bottom: 100px"
>
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <table class="exportable">
    <tr>
      <th id="lightblue">Export with Limited Quantity</th>
    </tr>
    <tr>
      <td>
        WM DP: -Do not exceed 5kg per AWB -Declare value exceed RM400 per AWB
        will incur duty tax -Product contain labels, trademarks and in proper
        packaging. -Same personal product do not exceed 10pcs per AWB
      </td>
    </tr>
    <tr>
      <td>
        EM DP: -Do not exceed 5kg per AWB -Declare value exceed RM400 per AWB
        will incur duty tax -Product contain labels, trademarks and in proper
        packaging. -Same personal product do not exceed 10pcs per AWB
      </td>
    </tr>
  </table>
</div>

<div
  class="section shipmentable"
  data-role="main"
  class="ui-content"
  style="overflow-x: auto; margin-top: 35px; margin-bottom: 100px"
>
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <table
    class="exportable"
    data-role="table"
    data-mode="columntoggle"
    id="myTable orange"
  >
    <tr>
      <th id="orange">Restricted</th>
    </tr>
    <tr>
      <td>
        WM DP: -Do not exceed 5kg per AWB -Products with batteries are
        prohibites. Only hearing aids without batteries can be ship -Receiver
        require to provide HSA certificates for clearance purpose -Declare value
        exceed RM1,000 per parcel will incur duty tax -Products must be in
        proper packaging and labelling
      </td>
    </tr>
    <tr>
      <td>
        EM DP: -Do not exceed 5kg per AWB -Products with batteries are
        prohibites. Only hearing aids without batteries can be ship -Receiver
        require to provide HSA certificates for clearance purpose -Declare value
        exceed RM1,000 per parcel will incur dury tax -Products must be in
        proper packaging and labelling
      </td>
    </tr>
  </table>
</div>
<app-footer></app-footer>
