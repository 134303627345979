import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

    faq_collection: any;
    showAll: boolean = false;

  constructor(
    private apiService: ApiService,
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit(): void {

    this.titleService.setTitle("Frequent Ask Question")

    const metaDesc = this.metaService.getTag('name=description');

    if(!metaDesc){
        this.metaService.addTags([
            {name: "description", content: "Questiones related to Line Clear Express"}
        ]);
    }else{
        this.metaService.updateTag(
            {name: "description", content: "Questiones related to Line Clear Express" },
            "name=description"
        )
    }

    this.retrieveFaqList();

  }

  retrieveFaqList(){

    this.apiService.getFaqList().subscribe((res: any) => {

        console.log('response: ', res)

        this.faq_collection = res.data;
    }, error => {
        //console.log('API Failed')
    })

  }

  clickHandler(){
    //   alert('here')

      var toggle = document.getElementsByClassName('expand');
      alert(toggle)
  }

  showAllTrigger(){
      this.showAll = !this.showAll;

    //   alert(this.showAll)
  }

  SearchTrigger(){
    var findInput = "" 

    findInput = (document.getElementById ("findInput") as HTMLInputElement).value.toLocaleLowerCase();
      if (findInput.length < 3 ) {
          alert ("Please enter more than 2 characters of text to search.");
          return;
      }
      else{
        var heading :any
        var childHeading :any
        var buttonCollapse:any
        var buttonCollapseChild:any
        var divCollapse:any
        var divCollapseChild:any
        var expand:any
        var expandChild:any
        var childId:any

        for (let i=0; i<this.faq_collection.length; i++){
          heading = this.faq_collection[i].question_str.toLocaleLowerCase();

          //header
          if(heading.search(findInput) != -1){
            //expand header
            buttonCollapse = (document.getElementById("headingButton"+i) as HTMLInputElement).classList.remove("collapsed");
            expand = (document.getElementById("headingButton"+i) as HTMLInputElement).setAttribute("aria-expanded","true");
            divCollapse = (document.getElementById("collapse"+i) as HTMLInputElement).classList.add("show");
 
          }
          else{
            //collapse header
            buttonCollapse = (document.getElementById("headingButton"+i) as HTMLInputElement).classList.add("collapsed");
            expand = (document.getElementById("headingButton"+i) as HTMLInputElement).setAttribute("aria-expanded","false");
            divCollapse = (document.getElementById("collapse"+i) as HTMLInputElement).classList.remove("show");
          }   
          
          
           //child header
           for(let j=0; j<this.faq_collection[i].hisChild.length; j++){
            childHeading = this.faq_collection[i].hisChild[j].question_str.toLocaleLowerCase();
            childId = this.faq_collection[i].hisChild[j].id

            if(childHeading.includes(findInput)){        
              //expand child header              
              buttonCollapseChild = (document.getElementById("childButton"+childId) as HTMLInputElement).classList.remove("collapsed");
              expandChild = (document.getElementById("childButton"+childId) as HTMLInputElement).setAttribute("aria-expanded","true");
              divCollapseChild = (document.getElementById("collapse_child"+childId) as HTMLInputElement).classList.add("show");
            }
            else{
              //collapse child header
              buttonCollapseChild = (document.getElementById("childButton"+childId) as HTMLInputElement).classList.add("collapsed");
              expandChild = (document.getElementById("childButton"+childId) as HTMLInputElement).setAttribute("aria-expanded","false");
              divCollapseChild = (document.getElementById("collapse_child"+childId) as HTMLInputElement).classList.remove("show");
            } 
           }
         }

      }

   }

}