<app-header></app-header>

<div class="section" id="track-section">
    <div class="background-image hidden-sm hidden-xs" style="background-image : url('./assets/image/desktop-tracktrace-01.jpg'); background-position: bottom !important;"></div>
    <div class="background-image hidden-lg hidden-md" style="background-image : url('./assets/image/mobile-tracktrace-01.jpg'); background-position: bottom !important;"></div>
    <div class="container" style="margin-top: 5%;">
      <div class="row">
        <div class="col-md-12">
          <div class="section mobile-pad">
            <div class="container">
              <div class="row">
                <div class="col-md-12" style="padding-bottom:10px;">
                  <h1 class="text-center">
                    <b>TRACK &amp; TRACE</b>
                  </h1>
                </div>
              </div>
              <div class="row">
                <div class="col-md-offset-3 col-md-6">

                  <form (submit)="getTrackerData('')" class="form-horizontal form-track" role="form">
                        <div class="form-group">
                            <div class="input-group input-group-lg">
                                <input [(ngModel)]="trackCode" [(value)]="trackCode" name="trackCode" type="text" class="form-control input-lg" id="inputTrackNo" placeholder="Enter your tracking number(s)">
                                <span class="input-group-btn">
                                    <button type="submit" class="btn btn-block btn-lg track-btn" id="track">TRACK NOW</button>
                                </span>
                            </div>
                        </div>

                        <div id="viewHere" style="margin-top: 100px;">

                        </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section" *ngIf="trackerDisplay" style="margin-top: 0px;">
    <div *ngFor="let list of trackerListRecon" class="container" style="margin-bottom: 50px;">
      <div class="row">
        <div class="col-md-12">
          <img [src]="list.image" class="center-block img-responsive step-img" style="margin-bottom: 10px;">
        </div>

        <div class="col-md-12 text-center" style="margin-top: 10px; margin-bottom: 10px;">
            <p class="label" style="background-color: #243F8D;">TRACKER ID: {{list.data['0'].WayBillNumber}}</p>    
        </div>
      </div>
      <div *ngFor="let item of list.data; first as isFirst" class="row" style="margin-top:15px; margin-bottom:15px;">
        <div class="col-md-8 col-md-offset-2 ">
          <div *ngIf="isFirst; else pendingJob" class="col-xs-4 col-sm-2 job-done" style="padding-right:0px; padding-left:0px;">
            <div class="col-md-12 time-col vcenter">
              <p class="text-right progress-text">{{item.LastModifiedDate}}
                <br>{{item.LastModifiedTime}}</p>
            </div>
          </div>

          <ng-template #pendingJob>
            <div class="col-xs-4 col-sm-2 job-pending" style="padding-right:0px; padding-left:0px;">
                <div class="col-md-12 time-col vcenter">
                  <p class="text-right progress-text">{{item.LastModifiedDate}}
                    <br>{{item.LastModifiedTime}}</p>
                </div>
            </div>
          </ng-template>

          <div class="col-xs-8 col-sm-10 " style="padding-right:0px; padding-left:10px;">
            <div class="col-md-12 detail-col vcenter">
              <p class=" progress-text">{{item.Description}}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="section">
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="height: 170px;"></div>
      </div>
    </div>
  </div>


  <app-footer></app-footer>

  <div *ngIf="visible" style="z-index: 9999999999999;">
    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" 
    size = "default" 
    color = "#fff" 
    type = "square-jelly-box" 
    [fullScreen] = "true">
        <p style="color: white" > Loading... </p>
    </ngx-spinner>
</div>