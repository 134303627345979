<app-header></app-header>

<!-- New  -->
<div class="section" id="section" [ngClass]="{'auto': isAuto, 'non_auto': !isAuto}">
    <div class="background-image background-image-fixed hidden-sm hidden-xs" style="background-image : url('./assets/image/desktop pitstop locator.jpg')"></div>
    <div class="background-image background-image-fixed hidden-lg hidden-md" style="background-image : url('./assets/image/mobile pitstop locator.jpg')"></div>
    <div class="container div-center">
      <div class="col-md-4" >
        <div class="col-md-12">
          <h3 class="text-left mobile-center" style="color: white;">
            <b>PITSTOP LOCATOR</b>
          </h3>
        </div>
        <div class="col-md-12">
            <form (submit)="pitstopLocator()">
              <div class="form-group">
                <div class="input-group-lg">
                  <input [(ngModel)]="postcode" [(value)]="postcode" name="postcode" type="text" class="form-control" placeholder="Enter a State, City or Postcode" style="font-size: 14px;">
                </div>
                <div class="input-group-lg mobile-center" style="text-align: right; padding-top: 10px;">
                  <span class="input-group-btn">
                    <button class="btn track-btn" type="submit" id="button">Find Now<i class="fa fa-flip-horizontal fa-truck"></i></button>
                  </span>
                </div>
              </div>
            </form>
        
        </div>
      </div>
    
      <div class="col-md-8" style="padding-left:0; padding-right:0; display:block;">
        <div class="row" style="padding:0;">
          <div *ngFor="let locator of branchLocator" class="col-md-6 col-sm-6 pit-div">
            <div class="col-md-12 pit-name">
              <h3 class="text-center no-margin2" style="text-transform: uppercase; font-weight: 900;">{{locator.place}} ({{locator.branchcode}})</h3>
            </div>
            <div class="col-md-12 pit-detail">
              <div class="col-md-12">
                <h4 style="font-weight: 700; padding-top:20px;" class="no-margin1">Address</h4>
                <p class="p-text">{{locator.address}} {{locator.postcode}} {{locator.city}}, {{locator.state}}.</p>
                <!-- <h4 style="font-weight: 700;">Person in Charge</h4>
                  <p class="p-text" *ngIf="locator.picname == null || locator.picname == ''; else noPIC">-</p>
                  <ng-template #noPIC>
                    <p class="p-text">{{locator.picname}}</p>
                  </ng-template> -->
                  <h4 style="font-weight: 700;">Service Provided</h4>
                  <p class="p-text">{{locator.function}}</p>
              </div>
              <div class="col-md-12">
                <h4 style="font-weight: 700;">Email</h4>
                <p *ngFor="let sub_email of locator.picemail?.split(';')" class="p-text">{{sub_email}}</p>
                <h4 style="font-weight: 700;">Contact Details</h4>
                <p *ngFor="let sub_phone of locator.phone?.split(';')" class="p-text">{{sub_phone}}</p>
              </div>
              <div class="col-md-12">
                <h4 style="font-weight: 700;">Operating Hours</h4>
                <p class="p-text">{{locator.operationhour.length >1 ? locator.operationhour:'8.00am-5.00pm'}}</p>
                <h4 style="font-weight: 700;">Find Us</h4>
                <p style="padding-bottom: 20px !important;" class="p-text">
                    <a href="waze://?ll={{locator.latitude}},{{locator.longtitude}}&amp;navigate=yes" style="text-decoration: none;">Waze</a>
                    &nbsp;| 
                    <a href="https://maps.google.com?q={{locator.longtitude}},{{locator.latitude}}" style="text-decoration: none;">Google Map</a>
                </p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>

</div>
   

<!-- end of New  -->


<div *ngIf="visible">
    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" 
    size = "default" 
    color = "#fff" 
    type = "square-jelly-box" 
    [fullScreen] = "true">
        <p style="color: white" > Loading... </p>
    </ngx-spinner>
</div>

<app-footer></app-footer>
