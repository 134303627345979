<app-header></app-header>

<div class="section" style="margin-top: 86px;">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <img src="./assets/image/Logo-Protection-Plan.png" class="PlanLogo center-block img-responsive">
        <h4 class="text-center">Why Choose Our Protection Plan?</h4>

      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-5 col-sm-5">
        <img src="./assets/image/affordable.png" class="icon center-block" style="margin-bottom: 10px;">
        <img src="./assets/image/backed_by_world.png" class="icon center-block" style="margin-bottom: 10px;">

      </div>
      <div class="col-md-7 col-sm-7">
        <ul>
          <li style="margin: 2%;"><b>Affordable:</b> Price starts as low as RM 3.00.</li>
          <li style="margin: 2%;"><b>Coverage:</b> Enjoy protection coverage up to RM 50,000 (terms and conditions
            apply).</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-3">
        <h4 style="font-size: medium;">Protect your parcel, free from worries!</h4>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 mx-auto">
        <img src="./assets/image/pp_price.png" class="img-responsive hidden-xs"
          style="max-width: 100%; margin-bottom: 10px;">
      </div>
      <div class="col-md-7 text-center">
        <img src="./assets/image/pp_price1.png" class="hidden-lg hidden-md hidden-sm " style="max-width: 25%;">
        <img src="./assets/image/pp_price2.png" class="hidden-lg hidden-md hidden-sm " style="max-width: 25%;">
        <img src="./assets/image/pp_price3.png" class="hidden-lg hidden-md hidden-sm " style="max-width: 25%;">
      </div>
      <div class="col-md-7 text-center">
        <img src="./assets/image/pp_price4.png" class="hidden-lg hidden-md hidden-sm " style="max-width: 25%;">
        <img src="./assets/image/pp_price5.png" class="hidden-lg hidden-md hidden-sm " style="max-width: 25%;">
      </div>
    </div>
    <div class="row center-block">
      <div class="col-md-12">
        <p style="font-size: small; padding: 15px 0px;"><b>Term & Conditions Apply: </b>
          High-risk items such as mobile phones, cosmetic products, health products, microchips,
          computers, laptops, and IT components, will subject to a 5% excess. </p>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>