<div class="navbar navbar-default navbar-fixed-top">
  <div class="container">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-ex-collapse">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a (click)="goToLanding()" class="navbar-brand" style="cursor: pointer;"><img height="100%" alt="Brand"
          src="https://lineclearexpress.com/my/public/assets/images/lineclearlogo.png" class="center-block"></a>
    </div>
    <div class="collapse navbar-collapse" id="navbar-ex-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li class="" style="cursor: pointer;">
          <a (click)="goToTracker()"><span class="effect">Track &amp; Trace</span></a>
        </li>
        <li style="cursor: pointer;">
          <a (click)="goToPrepaid()"><span class="effect">Line Clear Coins</span></a>
        </li>
        <li style="cursor: pointer;">
          <a (click)="goToPitstop()"><span class="effect">Pitstop Locator</span></a>
        </li>
        <li style="cursor: pointer;">
          <a (click)="goToAbout()"><span class="effect">About Us</span></a>
        </li>
        <li style="cursor: pointer;" class="hidden-sm hidden-xs">
          <div class="dropdown">
            <button class="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <a (click)="goToContact()"><span class="effect">Contact Us</span></a>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" (click)="goToJoinUs()"><span class="effect">Join Us</span></a>
            </div>
          </div>
        </li>
        <li style="cursor: pointer;" class="hidden-lg hidden-md">
          <a (click)="goToContact()"><span class="effect">Contact Us</span></a>
        </li>
        <li style="cursor: pointer;" class="hidden-lg hidden-md">
          <a (click)="goToJoinUs()"><span class="effect">Join Us</span></a>
        </li>
        <li style="cursor: pointer;">
          <a (click)="goToFaq()"><span class="effect">FAQ</span></a>
        </li>
        <li style="cursor: pointer;">
          <a (click)="goToPortal()"><button class="btn btn-block btn-info track-btn" type="button">LOG IN</button></a>
        </li>
      </ul>
    </div>
  </div>
</div>