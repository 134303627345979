// Core Module
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Directive } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

// External Module
import { NgxSpinnerModule } from 'ngx-spinner';
// import { FacebookModule } from "ngx-facebook";
import { SlickCarouselModule } from 'ngx-slick-carousel';
// import { LivechatWidgetModule } from '@livechat/angular-widget';

// Component
import { AppComponent } from './app.component';
import { PrepaidComponent } from './prepaid/prepaid.component';
import { QuoteComponent } from './quote/quote.component';
import { LandingComponent } from './landing/landing.component';
import { PitstopComponent } from './pitstop/pitstop.component';
import { HeaderComponent } from './header/header.component';
import { SectionComponent } from './section/section.component';
import { FooterComponent } from './footer/footer.component';
import { TrackerComponent } from './tracker/tracker.component';
import { ContactComponent } from './contact/contact.component';
import { TncComponent } from './tnc/tnc.component';
import { TncCarriageComponent } from './tnc-carriage/tnc-carriage.component';
import { PolicyComponent } from './policy/policy.component';
import { PrivacyPolicyComponent } from './privacy_policy/privacy_policy.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { JoinUsComponent } from './join-us/join-us.component';
import { PrepaidIntroComponent } from './prepaid-intro/prepaid-intro.component';
// import { AppearDirectiveDirective } from './appear-directive.directive';

// Animation
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProtectionPlanComponent } from './protection_plan/protection_plan.component';
import { FaqComponent } from './faq/faq.component';
import { ShipmentComponent } from './shipment/shipment.component';

@NgModule({
  declarations: [
    AppComponent,
    QuoteComponent,
    PrepaidComponent,
    LandingComponent,
    PitstopComponent,
    HeaderComponent,
    SectionComponent,
    FooterComponent,
    TrackerComponent,
    ContactComponent,
    TncComponent,
    TncCarriageComponent,
    PolicyComponent,
    PrivacyPolicyComponent,
    AboutUsComponent,
    ProtectionPlanComponent,
    FaqComponent,
    JoinUsComponent,
    ShipmentComponent,
    PrepaidIntroComponent,

    // AppearDirectiveDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxSpinnerModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    // FacebookModule.forRoot(),
    // LivechatWidgetModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    // AppearDirectiveDirective
    // RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [Title],
  bootstrap: [AppComponent],
})
export class AppModule {}
