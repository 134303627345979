<app-header></app-header>


<div class="section" style="margin-top: 5%;" id="about">
  <div class="container" style="margin-top: 50px; margin-bottom:50px;">
    <div class="row display-flex-center ">
      <div class="col-md-6 col-xs-12">
        <img src="./assets/image/aboutus1.png" data-aos="zoom-in-down" class="center-block img-responsive">
      </div>
      <div class="col-md-6 col-xs-12">
        <h1 class="text-left">About Us</h1>
        <p class="text-justify">Line Clear Express is a fully integrated supply chain management specialist providing
          seamless, reliable and efficient delivery services across Malaysia. Incorporated in 2015,
          Line Clear Express Sdn Bhd is a wholly-owned subsidiary of MMAG Holdings Berhad,
          a publicly-traded company on the ACE Market on Bursa Malaysia. &nbsp;</p>
      </div>
    </div>
    <div class="row" style="margin-top:20px; margin-bottom: 20px;">
      <div class="col-md-12 col-xs-12">
        <p class="text-justify">
          <br>Line Clear Express owns over 15 hubs, 29 depots and 99 Pitstops including several
          warehouses that are strategically located in Malaysia. With a fleet of over 800 vehicles,
          our services include courier services, freight services, transit insurance, transit clearance,
          customised and special handling services, warehousing, fulfilment, 3rd and 4th party logistics.
          We integrate the latest technology and infrastructure into our business operations to meet shipping
          requirements of any size.
        </p>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row" style="margin-bottom: 50px;">
      <div class="col-md-12">
        <h1 class="text-center">
          <b>Our Certification</b>
        </h1>
        <p>Line Clear Express has been awarded with the prestigious ISO 14001:2015 and ISO 9001:2015 certifications.
          ISO 14001:2015 is a globally recognized standard that outlines the requirements for an effective environmental
          management system (EMS),
          while ISO 9001:2015 is the standard for a quality management system (QMS). Line Clear Express's achievement of
          both these
          certifications demonstrates the company’s commitment to reducing environmental impact, implementing
          sustainable practices,
          and maintaining high-quality services for their customers. These awards are a testament to our dedication and
          hard work in
          ensuring that our business is conducted in an environmentally responsible and quality-oriented manner.</p>
        <br />

        <p>Line Clear Express' business adheres to the following legal requirements:</p>
        <ul>
          <li>MCMC Act</li>
          <li>APAD Act 2010</li>
          <li>LPKP Act 1987</li>
          <li>EQA 1974</li>
          <li>OSHA 1994</li>
          <li>FMA 1967</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <ul class="media-list">
          <li data-aos="zoom-in-down" class="">
            <div><a href="https://www.sgs.com/en-my" target="_blank" style="border-color: black;"><img
                  src="./assets/image/14001@2x-100.png" data-aos="zoom-in-down" class="img-responsive"
                  style="width:80%; height:60%; margin-left: 10%; border-style: solid; border-width: 2px;border-color: black;"></a>
              <div class="row"></div>
              <h5 style="text-align: center; margin-bottom: 7%;">ISO 9001:2015 Quality Management System</h5>
            </div>
          </li>
          <li data-aos="zoom-in-down" class="">
            <div><a href="https://www.sgs.com/en-my" target="_blank" style="border-color: black;">
                <img src="./assets/image/9001@2x-100.png" data-aos="zoom-in-down" class=" img-responsive"
                  style="width:80%; height:60%; margin-left: 10%;border-style: solid; border-width: 2px; border-color: black;"></a>
              <div class="row"></div>
              <h5 style="text-align: center; margin-bottom: 7%;">ISO 9001:2015 Quality Management System</h5>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <ul class="media-list">
          <li data-aos="zoom-in-down" class="">
            <div><a href="https://www.sgs.com/en-my" target="_blank" style="border-color: black;">
                <img src="./assets/image/14001 MS@2x-100.png" data-aos="zoom-in-down" class="img-responsive"
                  style="width:80%; height:60%; margin-left: 10%;border-style: solid; border-width: 2px; border-color: black;"></a>
              <div class="row"></div>
              <h5 style="text-align: center; margin-bottom: 7%;">ISO 14001:2015 Environmental Management Systems</h5>
            </div>
          </li>
          <li data-aos="zoom-in-down" class="">
            <div><a href="https://www.sgs.com/en-my" target="_blank" style="border-color: black;">
                <img src="./assets/image/9001 MS@2x-100.png" data-aos="zoom-in-down" class="img-responsive"
                  style="width:80%; height:60%; margin-left: 10%;border-style: solid; border-width: 2px; border-color: black;"></a>
              <div class="row"></div>
              <h5 style="text-align: center; margin-bottom: 7%;">ISO 9001:2015 Quality Management System</h5>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

</div>

<app-footer></app-footer>