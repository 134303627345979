<app-header></app-header>

<div class="section" style="margin-top: 5%;">
    <div class="container">
      <div class="row" style="margin: 50px 0px;">
        <div class="col-md-12">
          <h4 class="mbr-section-subtitle mbr-fonts-style mb-4 display-4">LINE CLEAR EXPRESS SDN BHD TERMS AND CONDITIONS OF CARRIAGE</h4>
          <ol start="1">
            <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">All and any business undertaken by
              <b>LINE CLEAR</b> EXPRESS SDN BHD [hereinafter referred to
              as
              <b>“LINE CLEAR”</b>] is transacted to the conditions hereinafter set out
              and shall be deemed to be a condition of any agreement between
              <b>LINE CLEAR</b> and its Customer [hereinafter referred to as the ‘Shipper’]
              or any person acting on behalf of the Shipper.</li>
            <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">The Shipper irrevocably warrants that the Shipper is the actual, legal
              or proprietary owner of any Shipment that is tendered to
              <b>LINE CLEAR</b> for carriage or transportation, shall irrevocably agrees
              and accepts the conditions herein not only for itself but also as agent
              for and on behalf of any person who may have interests in the Shipment
              and shall be deemed to have accepted and agreed to the terms and conditions
              herein, which terms and conditions shall be referred to as
              <b>LINE CLEAR EXPRESS SDN BHD TERMS AND CONDITIONS OF CARRIAGE</b>.</li>
            <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">
              <b>LINE CLEAR</b> shall not be bound by any agreement purporting to vary these
              conditions unless such agreement shall be in writing and signed on behalf
              of LINE CLEAR by an authorized Officer of
              <b>LINE CLEAR</b>. All conditions, warranties, descriptions, representations
              and agreements not set out in these conditions and whether they be expressed
              or implied by law, custom or other are hereby expressly excluded. No officer,
              servant, agent or representative is authorized to make any representations,
              statements, warranties, conditions and or agreement not expressly set forth
              in these conditions and
              <b>LINE CLEAR</b> is not in any way bound by any of them or to be taken as
              to form part of the contract with
              <b>LINE CLEAR</b> or collateral to the main contract.</li>
              <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">Whenever
                <b>LINE CLEAR</b> undertakes or arranges transport storage or any other services
                it shall be authorized to entrust the goods or arrangements to third parties,
                by any route and procedure and by any courier or successive and according
                to any handling, storage and transportation methods as
                <b>LINE CLEAR</b> thinks fit on the latter’s contractual conditions. Ultimately
                <b>LINE CLEAR</b> reserves the right NOT to carry the Shipper’s documents
                or goods</li>
              <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">
                <b>LINE CLEAR</b> is not a common carrier hence reserves the right to refuse
                the carriage or transportation of any class of goods at its absolute discretion.
                LINE CLEAR will not carry nor accept the following items (not exhaustive
                nor limited to) for courier delivery: cash (in any currency) cash cheques,
                blank or endorsed cheques, negotiable instruments or securities (share
                certificates, bonds, etc.), gold and silver bullion, coin, dust, cyanides,
                precipitates or any form of uncoined gold and precious and semi-precious
                stones including commercial carbons or industrial diamonds, antiques, livestock
                or plants, identity cards, passports, hazardous, combustible or explosive
                materials, prohibited drugs liquor, tobacco, or any goods or materials
                that are prohibited by law, rule or regulation of any country.</li>
              <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">The company will not accept or deal with any noxious, dangerous, hazardous
                or inflammable or explosive goods or any goods likely to cause damage.
                Any person delivering such good to the Company or causing the Company to
                handle or deal with any such goods shall be liable for all loss or damage
                caused thereby and shall indemnify the Company against all penalties, claims,
                damages, costs and expenses arising in connection therewith and the goods
                may be destroyed or otherwise dealt with at the sole discretion of the
                Company or any other person in whose custody they may be at the relevant
                time. The expression “goods” likely to cause “damage” include goods likely
                to harbour or encourage vermin or other pests.</li>
                <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">
                  <b>LINE CLEAR</b> reserves the right but not the obligation to inspect the
                  goods or documents consigned by its Shippers to ensure that all such documents
                  or goods are capable of carriage to the countries of destination within
                  the standard operating procedures. Customs declaration and handling methods
                  of
                  <b>LINE CLEAR</b> however, does not undertake or represent that any particular
                  item to be carried is capable and delivery without infringing the law of
                  any country or state from, to or through which the item may be carried.</li>
                <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">The Shipper warrant that neither the transaction nor the carriage of the
                  Shipment is in breach of any statute regulation, including but not limited
                  to governmental regulations and laws of any country to, from, through or
                  over the Shipment, or other law relating to postal or courier services,
                  and that they are either the owners of or the authorized agents of the
                  owners of any goods or property being the subject matter of the transaction
                  and by entering into the transaction they accept these conditions for themselves
                  as well as for all other parties on whose behalf they are acting. Shipper
                  and consignees of any Shipment and / or their agents, undertakes to indemnify
                  <b>LINE CLEAR</b> against losses, damages, expenses and fines arising from
                  any inaccuracy or omission even in such inaccuracy or omission is not due
                  to any negligence.</li>
                  <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">The making and packing of the Shippers’ documents or goods for transportation
                    is the Shipper’s sole responsibility including the placing of the documents
                    or goods in any container which may be supplied to the Shipper by
                    <b>LINE CLEAR</b> accepts no responsibility for loss or damage to documents
                    or goods caused by inadequate, inappropriate or defective packaging. It
                    is the sole responsibility of the Shipper to ensure that the address is
                    correct and legibly stated each consignment of documents or goods to enable
                    effective delivery to be made.
                    <b>LINE CLEAR</b> shall not be liable for delay in forwarding for delivery
                    resulting from the Shipper’s failure to comply with its obligations.</li>
                  <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">The Shipper shall bear and pay all duties, taxes, fines, imposts, expenses
                    and losses (including without prejudice to the generality of the foregoing,
                    charges for any additional Carriage undertaken) incurred or suffered by
                    reason thereof or by reason of any illegal, incorrect or insufficient marking,
                    numbering or addressing of the Shipment and comply with but not limited
                    to any imposed at any time before or during the Carriage relating to anti-terrorism
                    measures of customs, port and other authorities and Shipper warrants
                    <b>LINE CLEAR</b> that the description and particulars including, but not
                    limited to, weight, content, measure quantity, quality, condition, marks,
                    numbers and value are correct.</li>
                    <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">The Shipper and consignees and / or their agents, if any shall be liable
                      for any duty, tax, impost or outlays of whatsoever nature levied by the
                      authorities of any port or place for or in connection with the goods and
                      for any payments, fines, expenses, loss or damage incurred or sustained
                      by
                      <b>LINE CLEAR</b> in connection herewith. The Shipper warrants that each article
                      in any Shipment is accurately described and Shipper shall provide the relevant
                      documents to meet the export or importing requirements. The Shipper appoints
                      <b>LINE CLEAR</b> or one appointed by
                      <b>LINE CLEAR</b> as Shipper’s agent solely for performance of customer clearance.</li>
                    <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">The Actual Value of a document (which term shall include any item of no
                      commercial value which is transported hereunder) shall be ascertained by
                      reference to its cost of preparation or replacement, reconstruction or
                      reconstitution value at the time and place of shipment whichever is less.</li>
                      <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">The Actual Value of a parcel (which term shall include any item of commercial
                        value which is transported hereunder) shall be ascertained by reference
                        to its cost of repair or replacement, resale or fair market value at the
                        time and place of shipment, whichever is less. In no event shall such value
                        exceed the original cost of the article actually paid by the Shipper plus
                        10%.</li>
                        <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">All goods and documents relating to the goods shall be subject to a particular
                          and general lien and right of detention for monies due either in respect
                          of such goods or any particular or general balance or other monies due
                          from the sender owner or consignee and their agents to
                          <b>LINE CLEAR</b>, if any money due to LINE CLEAR is not paid within the
                          terms set out and agreed or within 14 days after the notice has been given
                          to the person from whom the money is due that such goods are being detained
                          and same may be dispose or otherwise at the sole discretion of
                          <b>LINE CLEAR</b> and at the expense of such a person for fees applied or
                          towards satisfaction of such indebtedness. The sender owner or consignee
                          and their agents hereby waives any cross claim payment due.</li>
                        <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">
                          <b>LINE CLEAR’s</b> charges are calculated according to the higher of actual
                          or volumetric weight and the shipment may be re-weighed and re-measured
                          by LINE CLEAR to confirm this calculation. For the purposes of ascertaining
                          the volumetric weight of any parcel, the following formula applies:
                          <b>Length x Width x Height (cm) / 6000 = Volumetric weight (Kgs).</b>
                        </li>
                        <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">Any delivery or service fee quoted by
                          <b>LINE CLEAR</b> is subject to service tax, value added tax and any other
                          applicable tax or GST, duty and expenses as may be imposed by any local
                          or foreign customs or similar authority in respect of the carriage of the
                          Shipper’s Shipment and the Shipper shall be primarily liable for such payment
                          and any additional surcharge, fine, legal cost or loss suffered by
                          <b>LINE CLEAR</b>.</li>
                        <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">
                          <b>LINE CLEAR’s</b> shipment charges do not include insurance cover for Shipments
                          in transit and
                          <b>LINE CLEAR</b> is under no obligation to provide such insurance coverage.
                          The Shipper will arrange insurance for their Shipment for the intended
                          transit. The insurance policy shall include a waiver of subrogation clause
                          for the benefit of
                          <b>LINE CLEAR</b>.</li>
                        <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">For delayed Shipments,
                          <b>LINE CLEAR’s</b> liability shall only be limited up to the sum of the courier
                          charges paid by the Shipper provided that such delay was due to the negligence
                          of
                          <b>LINE CLEAR</b>, its servants or agents.</li>
                        <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">
                          <b>LINE CLEAR</b> shall not be liable for or in respect of any loss or damage
                          suffered by the Shipper howsoever caused or arising and without limiting
                          the generality of the foregoing, whether caused or arising by reason or
                          on account of loss or damage to goods, missed delivery or non-delivery,
                          delay in delivery, concealed damage, deterioration, contamination, evaporation,
                          cancellations or delays in scheduled air flights, in customs procedures
                          or any reason at all.</li>
                        <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">
                          <b>LINE CLEAR</b> shall not under any circumstances be liable for loss or
                          damage resulting from or attributed to any quotations, statement, representation,
                          or information, whether oral or in writing howsoever, whosesoever or to
                          whomsoever made or given by or on behalf of
                          <b>LINE CLEAR</b> or by any servant, employee or agent of
                          <b>LINE CLEAR</b> as to the classification of or liability for amount scale
                          or rate of customs duty, excise duty or other impost or tax applicable
                          to any goods or property are such at
                          <b>LINE CLEAR</b> shall commit any breach of any Act Of Parliament regulation
                          or Ordinance or other law made in respect of the same.</li>
                        <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">
                          <b>LINE CLEAR’s</b> total liability under any circumstances whatsoever shall
                          not exceed RM200.00 per Shipment or the cost of replacement, whichever
                          is lower. For the purpose of establishing the amount of
                          <b>LINE CLEAR</b> liability under this clause value of a document of the goods
                          shall be ascertained by reference to their replacement or constitution
                          value at the time and place of shipment without reference to their commercial
                          utility to the Shipper and other items of consequential loss. Instances
                          where a higher value has been declared in which case the Shipment shall
                          be insured by the Shipper and the Shipper’s sole remedy shall be from the
                          proceeds of the insurance policy.</li>
                        <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">If the Shipper requires greater protection,
                          <b>LINE CLEAR</b> can arrange insurance for Shipper covering the actual cash
                          value in respect of loss of or physical damage to the Shipment, provided
                          the Shipper requests it via
                          <b>LINE CLEAR</b> systems and pays the applicable premium. Shipment insurance
                          does not cover indirect loss or damage, or loss or damage caused by delay.</li>
                        <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">
                          <b>LINE CLEAR</b> shall not be liable for any loss, damage, delay, shortage,
                          mis-delivery, non-delivery, misinformation or failure to provide information
                          in connection with Shipper’s Shipment which is (a) beyond the reasonable
                          control of the management of
                          <b>LINE CLEAR</b>; (b) caused by any fraud, willful acts, reckless acts for
                          which the management of
                          <b>LINE CLEAR</b> has exercised reasonable diligence; (c) resulting from of
                          Acts of God and (d) by the occurrence of a force majeure event including
                          but not limited to (1) severe weather; (2) fire and flood; (3) war, rebellion,
                          sabotage, riot, terrorism; (4) strike or industrial dispute of whatsoever
                          nature; (5) act or omission of local or overseas public authority and changes
                          in applicable law; (6) disruption of air or ground network; (7) mechanical
                          problems to modes of transportation / machinery or material shortage (i.e.
                          fuel and electricity).</li>
                          <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">
                            <b>LINE CLEAR</b> is not an air transport company.
                            <b>LINE CLEAR</b>’s undertaking is within the Warsaw Convention 1929 and
                            subsequent air carriage treaties and legislation.
                            <b>LINE CLEAR</b> acts as agents or its Shipper when consigning documents
                            or goods with a particular airline or carrier for onward carriage, without
                            prejudice to its general right of subrogation hereunder
                            <b>LINE CLEAR</b> shall have the right to recover compensation from any airline
                            or carrier for loss, damage or expense incurred by any Shipper on behalf
                            of that Shipper.</li>
                          <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">Any claim brought by a Shipper against
                            <b>LINE CLEAR</b> hereunder must be notified by the Shipper to an office of
                            <b>LINE CLEAR</b> in writing within 14 days of the date when the documents
                            or goods should have reached their destinations, after which date, no claim
                            howsoever shall be entertained by
                            <b>LINE CLEAR</b>. No claim shall be entertained until all outstanding due
                            to
                            <b>LINE CLEAR</b> from the Shipper have been fully settled. The amount of
                            any such claim shall not be set off any amount owing to
                            <b>LINE CLEAR</b>.</li>
                          <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">Shipments which cannot be delivered either because they are insufficiently
                            or incorrectly addressed or because they are not collected or accepted
                            by the consignee may be disposed or returned at
                            <b>LINE CLEAR’s</b> option at any time after the expiration of 21 days from
                            the date a notice in writing is sent to the address which the sender gave
                            to
                            <b>LINE CLEAR</b> on delivery of goods. All charges and expenses arising in
                            connection with the sale or return of the goods shall be paid by the Shipper.
                            Communication from any agent or correspondence of
                            <b>LINE CLEAR</b> to the effect that the goods cannot be delivered for any
                            reason shall be conclusive evidence of that fact.</li>
                          <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">By signing on the Consignment Documents, you shall authorize
                            <b>LINE CLEAR</b> to use your personal data for all lawful purpose including
                            but not limited to disclosure of your personal data by
                            <b>LINE CLEAR</b> to any government and / or requlatory authority to the extent
                            permitted by any applicable laws.</li>
                          <li class="mbr-text mbr-fonts-style display-7 textjustify listspacing">The Standard Terms and Conditions shall be subject to and construed in
                            accordance with the laws of Malaysia. The parties hereby irrevocably submit
                            to the exclusive jurisdiction of the courts of Malaysia.</li>
                          </ol>
        </div>
      </div>
    </div>
  </div>


  <app-footer></app-footer>