<app-header></app-header>

<div class="section" style="margin-top: 5%;">
    <div class="container">
        <div class="row" style="margin: 50px 0px;">
            <div class="col-md-12">
                <h4 class="mbr-section-subtitle mbr-fonts-style mb-4 display-5 text-center">Frequently Asked Questions
                    (FAQ) &nbsp;&nbsp;
                    <button class="btn btn-light showaAll md-2" (click)="showAllTrigger()">
                        <span><b>{{showAll ? 'Close All' : 'Open All'}}</b></span>
                    </button>
                    <div class="search-container" style="float:right;">
                        <!-- <input class="form-control col-md-10" type="text" id="findInput" placeholder="Search.." name="search"> -->
                        <button class="btn btn-outline-secondary showaAll hidden" id="btnSearch"
                            (click)="SearchTrigger();"><i class="fa fa-search"></i></button>
                        <input class="form-control" id="findInput" type="text" name="search" onkeydown="if (event.keyCode == 13)
                        document.getElementById('btnSearch').click()">
                    </div>
                </h4>

                <!-- start here  -->

                <div class="accordion" id="accordionContainer">
                    <div class="accordion-item border-bottom" *ngFor="let faq of faq_collection; let i = index"
                        style="border: none;">
                        <h2 class="accordion-header" [id]="'heading' + i">
                            <button [id]="'headingButton' +i" class="accordion-button collapsed" type="button"
                                data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + i" aria-expanded="true"
                                [attr.aria-controls]="'collapse' + i"
                                style="font-weight: bold;  box-shadow: unset; color: black">
                                <span [innerHTML]="faq.sort_no"></span>. &nbsp;&nbsp;
                                <span [innerHTML]="faq.question_str" style="margin-top: 1rem;"></span>
                            </button>
                        </h2>
                        <div [id]="'collapse' + i" class="accordion-collapse collapse" [ngClass]="{'show': showAll }"
                            [attr.aria-labelledby]="'heading' + i">

                            <div *ngIf="faq.answer_str == null || faq.answer_str == ''; else noAnswerStr">

                            </div>

                            <ng-template #noAnswerStr>
                                <div class="accordion-body">
                                    <p [innerHTML]="faq.answer_str"></p>
                                </div>
                            </ng-template>

                            <!-- child loop start  -->

                            <div class="accordion-item" *ngFor="let faq_child of faq.hisChild; let i = index"
                                style="border: none; padding-left: 25px;">
                                <div *ngIf="faq_child.parent_id == faq.id && faq_child.active == 1">
                                    <h2 class="accordion-header" [id]="'child' + i">
                                        <button [id]="'childButton' + faq_child.id" class="accordion-button collapsed"
                                            type="button" data-bs-toggle="collapse"
                                            [attr.data-bs-target]="'#collapse_child' + faq_child.id"
                                            aria-expanded="true" [attr.aria-controls]="'collapse_child' + faq_child.id"
                                            style="font-weight: bold;  box-shadow: unset; color: black">
                                            <p style="display: flex;"><span [innerHTML]="faq.sort_no"></span>.
                                                <span  [innerHTML]="faq_child.sort_no"></span>.&nbsp;&nbsp;
                                                <span [innerHTML]="faq_child.question_str" ></span>&nbsp;&nbsp;</p>
                                           
                                        </button>
                                    </h2>
                                    <div [id]="'collapse_child' + faq_child.id" class="accordion-collapse collapse"
                                        [ngClass]="{'show': showAll }" [attr.aria-labelledby]="'child' + faq_child.id">

                                        <div
                                            *ngIf="faq_child.answer_str == null || faq_child.answer_str == ''; else noAnswerStr">

                                        </div>

                                        <ng-template #noAnswerStr>
                                            <div class="accordion-body" style="padding-left: 60px;">
                                                <p [innerHTML]="faq_child.answer_str"></p>
                                            </div>
                                        </ng-template>
                                    </div>

                                </div>

                            </div>

                            <!-- child loop end  -->

                        </div>
                    </div>
                </div>

                <!-- end here  -->

            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>