import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PlatformLocation } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  url: string = 'http://localhost:8000';
  lineClearURL: any;
  currBaseURL: any;
  trackerURL: any;
  cmsURL: any;
  token: any =
    'eyJhbGciOiJIUzI1NiJ9.QkVTVF9MQ1VOSV9FU1NQTA.1FcVvOUwquYYuoyA5yBrPcOLNUDf8iJaAZCqNZgjVys';
  //   tokenCMS:any = 'UYbQRrlqvwYqteujwoQ4acsvdx3tEF2gBPiQWwQa';  // token arcutra-lineclear
  tokenCMS: any = 'wZuxWtEuQYeE7unWqMKryZKpXvYuqMK6Tbni23u8'; // token cms-lineclear

  constructor(
    private http: HttpClient,
    private platformLocation: PlatformLocation
  ) {
    this.checkBaseUrl();
  }

  checkBaseUrl() {
    this.currBaseURL = (this.platformLocation as any).location.origin;

    let prodURL = this.currBaseURL.match(/production.ai/g);
    let localURL = this.currBaseURL.match(/localhost/g);

    if (prodURL != null) {
      this.lineClearURL = 'https://lineclearexpress.com/my';
      this.trackerURL =
        'https://8ym3webome.execute-api.ap-south-1.amazonaws.com/production/1.0';
      //   this.cmsURL = "https://lineclear-cms.nfylegacy.biz.my"
      this.cmsURL = 'https://cms.lineclearexpress.com';
      //   https://lineclear-cms.nfylegacy.biz.my/api/retrieve_faq
    } else {
      this.lineClearURL = 'https://lineclearexpress.com/my';
      //this.lineClearURL = "http://:8080/lineclear-web2-api";
      this.trackerURL =
        'https://8ym3webome.execute-api.ap-south-1.amazonaws.com/production/1.0';
      //   this.cmsURL = "https://lineclear-cms.nfylegacy.biz.my"
      this.cmsURL = 'https://cms.lineclearexpress.com';
    }
  }

  getAnnouncer(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.tokenCMS}`,
        'Content-Type': 'application/json',
      }),
    };

    const url = this.cmsURL + '/api/retrieve_popup';
    return this.http.get(url, httpOptions);
  }

  getFaqList(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.tokenCMS}`,
        'Content-Type': 'application/json',
      }),
    };

    const url = this.cmsURL + '/api/retrieve_faq';
    return this.http.get(url, httpOptions);
  }

  getBannerList(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.tokenCMS}`,
        'Content-Type': 'application/json',
      }),
    };

    const url = this.cmsURL + '/api/retrieve_banner';
    return this.http.get(url, httpOptions);
  }

  getSliderList(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.tokenCMS}`,
        'Content-Type': 'application/json',
      }),
    };

    const url = this.cmsURL + '/api/retrieve_slider';
    return this.http.get(url, httpOptions);
  }

  getQuotation(zipfrom, zipto, weight, parceltype) {
    //new
    const url =
      '/api/quote1' +
      '?zipfrom=' +
      zipfrom +
      '&zipto=' +
      zipto +
      '&weight=' +
      weight +
      '&parceltype=' +
      parceltype;

    // old
    // const url =
    // "/quote/quote.php"+
    // "?zipfrom=" + zipfrom +
    // "&zipto=" + zipto +
    // "&weight=" + weight +
    // "&parceltype=" + parceltype;

    return this.http.get(this.lineClearURL + url);
  }

  postSendEmail(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    const url = '/quote/inquire.php';

    return this.http.post(this.lineClearURL + url, data, httpOptions);
  }

  getbranchplace(branchcode) {
    const url = '/api/branchplace' + '?search=' + branchcode;

    return this.http.get(this.lineClearURL + url);
  }

  getPitstopLocator(postcode) {
    //new
    const url = '/api/locatebranch' + '?postcode=' + postcode;

    //old
    // const url =
    // "/quote/locatebranch.php"+
    // "?search=" + postcode;

    //console.log(this.lineClearURL + url)

    return this.http.get(this.lineClearURL + url);
  }

  postTracker(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
        'Content-Type': 'application/json',
      }),
    };

    const url = this.trackerURL + '/viewandtrack';
    return this.http.post(url, data, httpOptions);
  }

  // API combine into One
  getExports(): Observable<any> {
    const url = `${this.lineClearURL}/api/exports`;
    return this.http.get<any>(url);
  }

}
