import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title, Meta } from '@angular/platform-browser';
import { ViewportScroller } from '@angular/common';

import Swal from 'sweetalert2'

@Component({
    selector: 'app-tracker',
    templateUrl: './tracker.component.html',
    styleUrls: ['./tracker.component.css']
})
export class TrackerComponent implements OnInit {

    trackerList: any;
    trackerListRecon: any = {};
    trackCode: any = "";
    trackerDisplay: boolean = false;
    visible: boolean = false;
    imageTracker: string = "./assets/image/step-1.png";
    trackerArr: any;
    elementId: string = "";

    constructor(
        private spinner: NgxSpinnerService,
        private route: Router,
        private apiService: ApiService,
        private titleService: Title,
        private metaService: Meta,
        private viewportScroller: ViewportScroller
    ) { }

    ngOnInit(): void {
        this.spinner.show();

        this.titleService.setTitle("Track & Trace Your Item | Express Delivery Service | Line Clear Express")

        const metaDesc = this.metaService.getTag('name=description');

        if (!metaDesc) {
            this.metaService.addTags([
                { name: "description", content: "Track & Trace Parcel with Line Clear Express Malaysia. Easy & Timely Delivery" }
            ]);
        } else {
            this.metaService.updateTag(
                { name: "description", content: "Track & Trace Parcel with Line Clear Express Malaysia. Easy & Timely Delivery" },
                "name=description"
            )
        }

        //console.log('URL 2: '+ location.href);
        if (location.href.includes('id=')) {
            this.elementId = location.href.substring(location.href.indexOf("=") + 1);
            this.elementId = this.elementId.split('_').join(',')
            this.trackCode = this.elementId
            document.getElementById("track").click();
            this.trackCode = ""

        }
        else {
            //do nothing
        }


    }

    showSpinner() {
        // visible return true 
        this.visible = true;


        // calling function after 2 second 
        setTimeout(() => {
            ;
            this.hideSpinner()
        }, 1500);
    }

    hideSpinner() {
        // visible return false 
        this.visible = false;
    }


    getTrackerData(elementId: any): void {

        this.visible = true;
        const trackerStr = this.trackCode

        var str_array = trackerStr.split(',');

        const dataArr = []

        for (var i = 0; i < str_array.length; i++) {
            // Trim the excess whitespace.
            str_array[i] = str_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
            // Add additional code here, such as:
            // console.log('tracker string: ' + str_array[i])

            dataArr.push(str_array[i])
        }


        let data = {
            "SearchType": "WayBillNumber",
            "WayBillNumber": dataArr
        }

        this.apiService.postTracker(data).subscribe((res: any) => {

            //console.log("res", res)

            var error_message_awb = "The waybill number(s) entered is invalid or does not exist in the system"
            var error_message_ship_ref = "Minimum 1 ShipmentRef required"
            var error_message = "is invalid or does not exist in the system"

            if (res.message !== error_message_awb) {
                this.trackerDisplay = true

                // this.trackerArr = res
                this.trackerList = res

                let trackerData = []
                let trackerlocation = []
                let Trackerlocation1 = []
                let i = 0;

                this.trackerList[0].forEach((element, index) => {
                    // console.log('element', element)
                    switch (element.StatusCode) {
                        case 'WB004':
                            var des = element.Description;
                            des = des.replace("Manifest Out Scan completed at Pickup branch at", "Checked Out and Departed to Branch from");
                            element.Description = des.split(" by ")[0];
                            trackerlocation.push(element.TransitLocation);
                            break;
                        case 'WB009':
                            var des = element.Description;
                            des = des.replace("Manifest Out Scan completed at Pickup Hub at", "Checked Out and Departed to Hub from");
                            element.Description = des.split(" by ")[0];
                            trackerlocation.push(element.TransitLocation);
                            break;
                        case 'WB013':
                            var des = element.Description;
                            des = des.replace("Manifest Out Scan completed", "Transit Cage Out");
                            element.Description = des.split(" by ")[0];
                            trackerlocation.push(element.TransitLocation);
                            break;
                        case 'WB017':
                            var des = element.Description;
                            des = des.replace("Ready for Delivery", "Parcel has Arrived");
                            element.Description = des.split(" by ")[0];
                            trackerlocation.push(element.TransitLocation);
                            break;
                        case 'WB030':
                            var des = element.Description;
                            des = des.replace("Manifest Out from Station at", "Checked Out and Departed to Station from");
                            element.Description = des.split(" by ")[0];
                            trackerlocation.push(element.TransitLocation);
                            break;
                        case 'WB031':
                            var des = element.Description;
                            des = des.replace("Manifest Out from Platform/Hotspot at", "Checked Out and Departed to Platform/Hotspot from");
                            element.Description = des.split(" by ")[0];
                            trackerlocation.push(element.TransitLocation);
                        case 'WB005': case 'WB007': case 'WB010': case 'WB014':
                        case 'WB016': case 'WB019': case 'WB022': case 'WB023':
                        case 'WB025': case 'WB026':
                            // delete element.Description;
                            // delete element.LastModifiedDate;
                            // delete element.LastModifiedOn;
                            // delete element.LastModifiedTime;
                            // delete element.NextLocation;
                            // delete element.Status;
                            // delete element.StatusCode;
                            // delete element.TransitLocation;
                            // delete element.UndelCode;
                            // delete element.UndelReason;
                            // delete element.WayBillNumber;
                            this.trackerList[0][index] = {};
                            break;
                        default:
                            var des = element.Description;
                            element.Description = des.split(" by ")[0];
                            trackerlocation.push(element.TransitLocation);
                            break;
                    }

                    if (element.StatusCode == "WB020") {
                        switch (element.UndelCode) {
                            case 'V001':
                                element.Description = "UnDelivered (Consignee not available)"
                                break;
                            case 'V002':
                                element.Description = "UnDelivered (Incomplete Address)"
                                break;
                            case 'V003':
                                element.Description = "UnDelivered (Return To Shipper (RTS))"
                                break;
                            case 'V004':
                                element.Description = "UnDelivered (Bad Weather)"
                                break;
                            case 'V005':
                                element.Description = "UnDelivered (Office Collection)"
                                break;
                            case 'V006':
                                element.Description = "UnDelivered (Unable to Complete)"
                                break;
                            case 'V007':
                                element.Description = "UnDelivered (Short Piece(s))"
                                break;
                            case 'V008':
                                element.Description = "UnDelivered (ODA Delivery - Delivery on selected days)"
                                break;
                            case 'V009':
                                element.Description = "UnDelivered (Closed on arrival)"
                                break;
                            case 'V010':
                                element.Description = "UnDelivered (No show at destination)"
                                break;
                            case 'V011':
                                element.Description = "UnDelivered (Return to origin)"
                                break;
                            case 'V012':
                                element.Description = "UnDelivered (Suspected lost)"
                                break;
                            case 'V013':
                                element.Description = "UnDelivered (Suspected Damage)"
                                break;
                            case 'V014':
                                element.Description = "UnDelivered (Misrouted)"
                                break;
                            case 'V015':
                                element.Description = "UnDelivered (Wrong IC/Passport no.)"
                                break;
                            case 'V016':
                                element.Description = "UnDelivered (COD amount not ready)"
                                break;
                            case 'V017':
                                element.Description = "UnDelivered (Consignee Shifted)"
                                break;
                            case 'V018':
                                element.Description = "UnDelivered (Unable to contact consignee)"
                                break;
                            case 'V019':
                                element.Description = "UnDelivered (Cancel order)"
                                break;
                            case 'V020':
                                element.Description = "UnDelivered (Consignee refused to accept)"
                                break;
                            case 'V021':
                                element.Description = "UnDelivered (Change delivery date)"
                                break;
                            case 'V022':
                                element.Description = "UnDelivered (Restrictions delivery areas)"
                                break;
                            case 'V023':
                                element.Description = "UnDelivered (Business closed down)"
                                break;
                            case 'V024':
                                element.Description = "UnDelivered (Unclear Waybill Information)"
                                break;
                            case 'V025':
                                element.Description = "UnDelivered (Receiver Refuse To Pay Cod Or Duty Tax)"
                                break;
                            case 'V026':
                                element.Description = "UnDelivered (Dispose The Shipment)"
                                break;
                            case 'V027':
                                element.Description = "UnDelivered (Parcel On Hold For Investigation)"
                                break;
                            case 'V028':
                                element.Description = "UnDelivered (Bad Packaging)"
                                break;
                            case 'V029':
                                element.Description = "UnDelivered (Over Working Hour)"
                                break;
                            case 'V030':
                                element.Description = "UnDelivered (Receiver Request To Change Address)"
                                break;
                            case 'V031':
                                element.Description = "UnDelivered (Process For Claim)"
                                break;
                            case 'V032':
                                element.Description = "UnDelivered (Uncontrollable Situation)"
                                break;
                            case 'V033':
                                element.Description = "UnDelivered (Delivery Cancelled)"
                                break;
                            case 'V034':
                                element.Description = "UnDelivered (Cross Label)"
                                break;
                            case 'V035':
                                element.Description = "UnDelivered (Parcel'S Shipment Fee Or Weight Is Incorrect)"
                                break;
                            case 'V036':
                                element.Description = "UnDelivered (Bad Packaging)"
                                break;
                            case 'V037':
                                element.Description = "UnDelivered (Postcode Or Address Not Match)"
                                break;
                            default:
                                break;
                        }
                    }

                });

                this.apiService.getbranchplace(trackerlocation).subscribe((res: any[]) => {
                    Trackerlocation1 = res;
                    let i = 0;
                    this.trackerList[0].forEach(element => {
                        if (typeof element.TransitLocation === 'string') {
                            element.Description = element.Description.replace(element.TransitLocation, Trackerlocation1[i]);
                            i++;
                        }
                    });
                });

                this.trackerList.forEach(trackerID => {
                    let trackingstatus = trackerID[0]
                    // check 1st object since it is the latest status 

                    if (trackerID[0].message != undefined && trackerID[0].message.includes(error_message)) {
                        //not push when message return "is invalid or does not exist in the system"
                        //do nothing
                    }
                    else {
                        switch (trackingstatus.Status) {
                            case "Delivered":
                                this.imageTracker = "./assets/image/step-5.png"
                                break;
                            case "Out for Delivery":
                                this.imageTracker = "./assets/image/step-4.png"
                                break;
                            case "Order Placed":
                                this.imageTracker = "./assets/image/step-1.png"
                                break;
                            default:
                                // all other status will be in transit status 
                                this.imageTracker = "./assets/image/step-3.png"
                                break;
                        }

                        trackerData.push({ "data": trackerID, "image": this.imageTracker })
                    }
                });


                this.trackerListRecon = trackerData
                //console.log('json data: ', this.trackerListRecon)

                var newArray: any = {};
                newArray = this.trackerListRecon[0].data.filter(value => Object.keys(value).length !== 0);
                this.trackerListRecon[0].data = newArray
                //console.log('json data2 : ', this.trackerListRecon)

                this.trackCode = ""
                window.history.replaceState(null, document.title, "/tracker");

            }
            else {
                this.trackerDisplay = false

                // looking for ShipmentRef

                let data2 = {
                    "SearchType": "ShipmentRef",
                    "ShipmentRef": dataArr
                }

                this.apiService.postTracker(data2).subscribe((res: any) => {
                    // console.log('raw resp:', res)

                    this.trackerDisplay = true
                    this.trackerList = res
                    let trackerData = []

                    if (this.trackerList.message != undefined) {
                        if (this.trackerList.message == error_message_ship_ref) {
                            Swal.fire("Not Found!", "Error : <small style='color: red; font-style: italic;'>Item Not Found!</small>", "error")
                            this.trackerDisplay = false;
                            this.trackCode = "";
                            return false;
                        }
                        else if (this.trackerList.message.includes(error_message)) {
                            Swal.fire("Not Found!", "Error : <small style='color: red; font-style: italic;'>Item Not Found!</small>", "error")
                            this.trackerDisplay = false;
                            this.trackCode = "";
                            return false;
                        }
                    }

                    this.trackerList[0].forEach(element => {
                        //console.log('element', element)

                        if (element.StatusCode == "WB020") {

                            switch (element.UndelCode) {
                                case 'V001':
                                    element.Description = "UnDelivered (Consignee not available)"
                                    break;
                                case 'V002':
                                    element.Description = "UnDelivered (Incomplete Address)"
                                    break;
                                case 'V003':
                                    element.Description = "UnDelivered (Return To Shipper (RTS))"
                                    break;
                                case 'V004':
                                    element.Description = "UnDelivered (Bad Weather)"
                                    break;
                                case 'V005':
                                    element.Description = "UnDelivered (Office Collection)"
                                    break;
                                case 'V006':
                                    element.Description = "UnDelivered (Unable to Complete)"
                                    break;
                                case 'V007':
                                    element.Description = "UnDelivered (Short Piece(s))"
                                    break;
                                case 'V008':
                                    element.Description = "UnDelivered (ODA Delivery - Delivery on selected days)"
                                    break;
                                case 'V009':
                                    element.Description = "UnDelivered (Closed on arrival)"
                                    break;
                                case 'V010':
                                    element.Description = "UnDelivered (No show at destination)"
                                    break;
                                case 'V011':
                                    element.Description = "UnDelivered (Return to origin)"
                                    break;
                                case 'V012':
                                    element.Description = "UnDelivered (Suspected lost)"
                                    break;
                                case 'V013':
                                    element.Description = "UnDelivered (Suspected Damage)"
                                    break;
                                case 'V014':
                                    element.Description = "UnDelivered (Misrouted)"
                                    break;
                                case 'V015':
                                    element.Description = "UnDelivered (Wrong IC/Passport no.)"
                                    break;
                                case 'V016':
                                    element.Description = "UnDelivered (COD amount not ready)"
                                    break;
                                case 'V017':
                                    element.Description = "UnDelivered (Consignee Shifted)"
                                    break;
                                case 'V018':
                                    element.Description = "UnDelivered (Unable to contact consignee)"
                                    break;
                                case 'V019':
                                    element.Description = "UnDelivered (Cancel order)"
                                    break;
                                case 'V020':
                                    element.Description = "UnDelivered (Consignee refused to accept)"
                                    break;
                                case 'V021':
                                    element.Description = "UnDelivered (Change delivery date)"
                                    break;
                                case 'V022':
                                    element.Description = "UnDelivered (Restrictions delivery areas)"
                                    break;
                                case 'V023':
                                    element.Description = "UnDelivered (Business closed down)"
                                    break;
                                default:
                                    break;
                            }
                        }
                    });

                    this.trackerList.forEach(trackerID => {
                        let trackingstatus = trackerID[0]
                        //console.log("trackerID[0]", trackerID[0].message)
                        // check 1st object since it is the latest status 

                        if (trackerID[0].message != undefined && trackerID[0].message.includes(error_message)) {
                            //not push when message return "is invalid or does not exist in the system"
                            //do nothing
                        }
                        else {
                            switch (trackingstatus.Status) {
                                case "Delivered":
                                    this.imageTracker = "./assets/image/step-5.png"
                                    break;
                                case "Out for Delivery":
                                    this.imageTracker = "./assets/image/step-4.png"
                                    break;
                                case "Order Placed":
                                    this.imageTracker = "./assets/image/step-1.png"
                                    break;
                                default:
                                    // all other status will be in transit status 
                                    this.imageTracker = "./assets/image/step-3.png"
                                    break;
                            }

                            trackerData.push({ "data": trackerID, "image": this.imageTracker })
                        }
                    });

                    this.trackerListRecon = trackerData
                    // console.log('json data: ', this.trackerListRecon)
                    var newArray: any = {};
                    newArray = this.trackerListRecon[0].data.filter(value => Object.keys(value).length !== 0);
                    this.trackerListRecon[0].data = newArray
                    this.trackCode = ""
                    window.history.replaceState(null, document.title, "/tracker")

                })
            }
        }, error => {
            Swal.fire("Not Found!", "Error : <small style='color: red; font-style: italic;'>Item Not Found!</small>", "error")
            this.trackerDisplay = false
        })


        setTimeout(() => {
            ;
            this.visible = false
            this.viewportScroller.scrollToAnchor(elementId);
        }, 1500);


    }

}
