<app-header></app-header>

<div class="section" style="margin-top: 5%;">
    <div class="container">
      <div class="row" style="margin: 50px 0px;">
        <div class="col-md-12">
          <h4 class="mbr-section-subtitle mbr-fonts-style mb-4 display-5" style="text-align: left;">
            <strong>Privacy Policy</strong>
          </h4>
          <h4 class="mbr-section-subtitle mbr-fonts-style mb-4 display-5" style="text-align: left;">
            <strong>PERSONAL DATA PROTECTION NOTICE</strong>
          </h4>
          <p class="mbr-text mbr-fonts-style display-7 textjustify " style="text-align: left;">
            <strong>(For Customers, Service Providers and Business Associates)</strong>
          </p>
          <p class="mbr-text mbr-fonts-style display-7 textjustify " style="text-align: left;">
            Line Clear Express Sdn Bhd including its branches in Malaysia is committed to 
            keep individual information of people (“customers and clients”) protected and secured. We trust 
            this Notice causes you realise how we handle the individual information about our data subjects 
            that we gather and how data subjects can control our revelation of such information. 
            Line Clear Express Sdn Bhd is subject to the Personal Data Protection Act 2010 and 
            this Notice seeks to outline how we intend to deliver all the rights and protections and on what 
            data subjects are entitled to.
          </p>
          <br>
          <p class="mbr-text mbr-fonts-style display-7 textjustify ">
            <strong>Security of Personal Information</strong>
            <br>
            The security of individual information about you is our importance. We secure this information by 
            keeping up physical, electronic, and procedural protections that meet or surpass material laws. 
            We train our representatives in the best possible treatment of individual information. When we 
            utilize 3rd parties to give services to us, we expect them to ensure the privacy of individual 
            information that is transmitted to them.</p>
            <br>
          <p class="mbr-text mbr-fonts-style display-7 textjustify ">
            <strong>What personal data do we collect and how do we collect your data?</strong>     
            <br>
            Personal data refers to any information that relates directly or indirectly to an individual, 
            who is identified or identifiable from that information or from that and other information in 
            the possession of Line Clear Express, including any sensitive personal data and 
            expression of opinion, video recordings made through close circuit security surveillance cameras 
            placed for security reasons and audio recordings about the individual.</p>
          <p class="mbr-text mbr-fonts-style display-7 textjustify ">
            The types of personal data we collect may include, but is not limited to your name, address, 
            other contact details, age, occupation, marital status, financial information such as your income, 
            or income tax particulars your identity card or passport, place of birth, credit history and your 
            transaction history.</p>
          <p class="mbr-text mbr-fonts-style display-7 textjustify ">
            TFailure to provide such personal data/sensitive personal data may result in 
            Line Clear Express Sdn Bhd being unable to provide you with effective and 
            continuous products and services.</p>
          <p class="mbr-text mbr-fonts-style display-7 textjustify ">
            Line Clear Express will collect your personal data through the following channels:</p>
            <ul class="mbr-text mbr-fonts-style display-7 textjustify" style="font-size: 14px;">
              <li>When you subscribe to one of our services/products or activate an account with us.</li>
              <li>When you register at Line Clear Express website.</li>
              <li>When you contact any Line Clear Express entities/representatives through 
                  different approaches. For example, application forms, emails and letters, telephone calls 
                  and conversations you have with our staff at HQ/branch. In the event that you reach us or 
                  we get in touch with you utilizing phone, we may screen or record the telephone call for 
                  quality affirmation, training and security purposes.</li>
              <li>We may likewise acquire your own information when you take an interest in client surveys 
                  or when you agree to accept to join any of our promotion campaigns/competitions.</li>
              <li>When we acquire any information and data from approved external parties 
                  (for example credit reference offices, administrative and enforcement offices, recruitment 
                  organizations, business owners, schools, colleges, legitimate delegates).</li>
            </ul>
            <br>
            <p class="mbr-text mbr-fonts-style display-7 textjustify ">
              <strong>The purposes for collecting your personal data:</strong>
              <br>
              The purpose for which personal data is collected and further processed depends on the nature 
              of your relationship with us and/or our third-party service providers. Your personal data may 
              be used for the following purposes: –
              <br>
            </p>
            <ul class="mbr-text mbr-fonts-style display-7 textjustify " style="font-size: 14px;">
              <li>To process applications for the opening of your account with us for any product and services.</li>
              <li>To verify your financial position through a 3rd party approved credit agency.</li>
              <li>To manage internal record keeping and maintain your account and facility.</li>
              <li>For internal analysing purposes to study our marketing initiative effectiveness, market survey,
                  statistical analysis and modelling, reporting, audit and risk management, avoid fraud and any other 
                  purposes relating to the business’ operations.</li>
              <li>For direct marketing and telemarketing purposes</li>
              <li>To respond to inquiries &amp; complaints</li>
              <li>For such other purposes as permitted by applicable law or with your consent</li>
            </ul>
            <br>
            <p class="mbr-text mbr-fonts-style display-7 textjustify ">
              <strong>Disclosure of your personal data:</strong>
              <br>
              Your personal data will be kept confidential but such personal data may be provided to 
              third parties who carry out functions on behalf of Line Clear Express Sdn 
              Bhd whom we have contractual agreements for some of our functions, services and activities 
              for the purposes set out below: –
              <br>
            </p>
            <ul class="mbr-text mbr-fonts-style display-7 textjustify " style="font-size: 14px;">
              <li>Providing data to approved delivery partners, (Sea/Air/Land freight)</li>
              <li>Providing data to financial services (Insurance)</li>
              <li>Providing data to Customer Service providers</li>
              <li>Providing data to business professional advisors and external auditors.</li>
              <li>Providing data to Telemarketing agencies</li>
              <li>Providing data to Database system providers</li>
              <li>Providing data to e-Commerce providers</li>
              <li>Providing data to Online payment gateway partners</li>
              <li>Providing data to SMS service providers</li>
              <li>To any of our affiliates in and outside Malaysia who is/are connected to 
                  Line Clear Express Sdn Bhd;</li>
            </ul>
            <p class="mbr-text mbr-fonts-style display-7 textjustify ">
              The revelation of your information may include the exchange of your own information to 
              places outside of Malaysia, and by giving us your own information you consent to such an 
              exchange where it is required to give you the services/ products you have asked for, and 
              for the execution of any legally binding commitments you have with Line Clear Express Sdn Bhd.
            </p>
            <br>
            <p class="mbr-text mbr-fonts-style display-7 textjustify ">
              <strong>We may share your information for Our Protection and the Protection of Others.</strong>
              <br>
              We reserve the right to disclose your personally identifiable information as required 
              by law and when we believe that disclosure is necessary to protect our rights and/or 
              comply with a judicial proceeding, court order, or legal process served on our Website; 
              enforce or apply this Privacy Policy, our Website Terms of Use or other agreements; or 
              protect the rights, property or safety of the Website, its Users or others.</p>
              <br>
              <p class="mbr-text mbr-fonts-style display-7 textjustify ">
              <strong>Your Rights</strong>
              <br>
              a) Right to request access to personal data
              <br>
              You are entitled to request access to your personal data that is processed by us with 
              reasonable written notice and we will revert within 14 working days.&nbsp;
              <br>
              b) b) Right to request correction of personal data
              <br>
              You are entitled to request the correction of your personal data that is held by us 
              with reasonable written notice. With respect to this, you may:</p>
            <ol class="mbr-text mbr-fonts-style display-7 textjustify " style="font-size: 14px;">
              <li>request correction and/ or update of your personal data that is inaccurate, 
                  incomplete or out-of-date; and</li>
              <li> request retention of your personal data only as long as necessary for 
                  the fulfilment of the Purpose stated above.</li>
            </ol>
            <br>
            <p class="mbr-text mbr-fonts-style display-7 textjustify ">
              <strong>Complaints and Disputes</strong>
              <br>
              If you have reason to believe that we have breached the principles as laid out in this 
              notice, we urge you to raise it with us.&nbsp;</p>
              <br>
              <p class="mbr-text mbr-fonts-style display-7 textjustify ">
              <strong>Your Privacy Preferences and How to Contact Us:</strong>
              <br>
              Please remember that we will protect your personal data regardless of your privacy preferences.&nbsp;
              <br>
              <br>
              If: (a) you wish to find out more information, or raise any specific or general concerns about 
              Line Clear Express Sdn Bhd and this Notice, you may: –&nbsp;
              <br>
              Call: <strong>03- 7890 3535</strong>
              <br>
              Email: <strong>customerservice@lineclearexpress.com</strong>
            </p>
            <p class="mbr-text mbr-fonts-style display-7 textjustify ">
              Line Clear Express Sdn Bhd has the right to refuse your requests to access and/or 
              to make any correction to your Personal Data for reasons permitted under the law, such as the 
              expense of providing access to you is disproportionate to the risks to your privacy.
              <br>
              This Policy is subject to changes and we reserve the right to amend, alter and/or vary this 
              Policy from time to time. Notification of changes would be made known to you through any 
              suitable medium of communication or through our website. In any event of a conflict between 
              the English and Bahasa Malaysia language versions of this Policy, the English version shall prevail.</p>
              <br>
            <p class="mbr-text mbr-fonts-style display-7 textjustify "> 
              Date of update of this Privacy Policy: 8 December 2021</p>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>